<template>
  <div>
    <MDBContainer v-if="$route.name == 'CheckingsList'" fluid class="p-0">
      <div
        v-if="$store.state.app.windowSize !== ('xs' && 'sm')"
        class="d-flex mt-2 ms-3"
      >
        <a
          @click="appearance = 'Card'"
          role="button"
          class="d-block mx-2 color-current"
        >
          <MDBIcon icon="th-large" iconStyle="fas" size="2x" />
        </a>
        <a
          @click="appearance = 'List'"
          role="button"
          class="d-block mx-2 color-current"
        >
          <MDBIcon icon="th-list" iconStyle="fas" size="2x" />
        </a>
      </div>

      <MDBRow
        class="
          container-fluid
          d-flex
          justify-content-evenly
          flex-wrap
          h-fit
          w-fit
          mx-auto
          px-3
        "
      >
        <CheckingSum
          v-for="checking of $store.state.checkingsList"
          :key="checking.Id"
          :checking="checking"
          :appearance="appearance"
        />
      </MDBRow>
    </MDBContainer>

    <router-view v-else></router-view>
  </div>
</template>

<script setup>
import { MDBIcon, MDBContainer, MDBRow } from "mdb-vue-ui-kit";

import CheckingSum from "@/components/checking/CheckingSum";

import { onMounted, ref } from "vue";
import { useStore } from "vuex";
const store = useStore();

const appearance = ref(store.state.app.windowSize === "sm" ? "List" : "Card");

onMounted(() => {
  store.dispatch("getAll");
});
</script>
